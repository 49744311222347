import React from 'react'
import { Jumbo } from '../components/jumbotron'
import { Container, Accordion, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { FaAngleDown} from 'react-icons/fa'


import Layout  from '../components/layout'
import SEO from '../components/seo'


const Services = () => (
  <Layout>
    <SEO title="Služby" />
    <Styles>
      <Jumbo title="Služby"></Jumbo>
      <Container className="services">
        <h2>Čo ponúkame</h2>
        <Accordion>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            <div className="d-flex align-items-center py-2">
              <h5>Profesionálna dentálna hygiena plus Air Flow</h5>
              <FaAngleDown size={30} className="ml-auto mb-1 mr-2"/>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="mt-3">
              <p>Cieľom je udržiavať zdravšie zuby a ďasná, predchádzať kazom a chorobám ďasien. Zdravé ďasná sú dlhodobou investíciou pre krásne zuby a v zubnej ambulancii sa bude sledovať stav Vašej ústnej hygieny a pomôžeme Vám dosiahnuť zdravšie ústa. Neriskujte so svojím zubným zdravím. Aj keď práve teraz nemáte žiadne problémy so zubami či ďasnami, ak zanedbáte svoju zubnú hygienu, problémy sa vyskytnú neskôr.<br/><br/>
         Pri Vašej návšteve Vám:
              </p>
              <ul>
                <li>Zhodnotíme zdravie ústnej dutiny a ďasien.</li>
                <li>Dôkladné vyčistíme a vyleštíme zuby, a to aj pod úrovňou ďasien.</li>
                <li>Identifikujeme orálny stav, ako je zápach z úst, sucho v ústach a pomoc pri ich liečení podľa potreby.</li>
                <li>Poradíme o perorálnych výrobkoch na podporu špecifických ústnych podmienok.</li>
                <li>Inštruujeme o správnych technikách čistenia a flossingu a povieme o vhodných výrobkoch ústnej hygieny vyhovujúcich jednotlivcom.</li>
                <li>Načrtneme program osobnej hygieny ústnej dutiny s odporúčanými 3, 6 alebo 12 mesačnými návštevami, odporúčanou budúcou liečbou a doporučením špecifickej liečby, ak je to potrebné.</li>
              </ul>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <Accordion>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            <div className="d-flex align-items-center py-2">
              <h5>Fixné náhrady- korunky a mostíky</h5>
              <FaAngleDown size={30} className="ml-auto mb-1 mr-2"/>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="mt-3">
              <p>Cieľom korunky je zachrániť a chrániť existujúci zub poškodený traumou alebo kazom. V prípadoch, keď zub podstúpil ošetrenie koreňového kanálika, môže byť po odstránení drene potrebná korunka na posilnenie zubu.</p>
              <p>Mostík je podobný korunke, ale líši sa tým, že nahrádza jeden alebo viac chýbajúcich zubov. Pripravený mostík je ukotvený k prírodným zubom na oboch stranách medzery. Ako už názov napovedá, zubný mostík doslova premosťuje medzeru, ktorú zanechal chýbajúci zub. Existujúce susedné zuby pôsobia ako kotvy náhradného zuba. Tieto susedné zuby sú „zakryté“ zubnými korunkami, aby sa zabezpečil náhradný zub na chýbajúcom mieste. Táto konštrukcia predstavuje účinný a nákladovo efektívny prístup k nahradeniu chýbajúceho zuba.</p>
              <p>Korunky a mostíky sú vyrobené z porcelánu, resp. zirkónu alebo porcelánu pritaveného na kov. Porcelán je vyrobený tak, aby zodpovedal prirodzenej farbe zubov a bol odolný voči škvrnám. Kov poskytuje korunke pevnosť, tvrdosť a trvanlivosť, čo je pre stoličky najužitočnejšie, pretože sa používajú na drvenie a rozomieľanie potravy.</p>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <Accordion>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            <div className="d-flex align-items-center py-2">
              <h5>Kozervačná stomatológia</h5>
              <FaAngleDown size={30} className="ml-auto mb-1 mr-2"/>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="mt-3">
              <h6>Výplne zubov</h6>
              <p>
      Jedným z najbežnejších spôsobov liečby v OCEAN DENT je ošetrenie kazov, ktoré sa zvyčajne riešia výplňami. V súčasnosti existujú rôzne druhy výplní; dočasné, dlhodobé provizóriá ako skloionomérne cementy, kompozit a amalgám. Preferujeme a aplikujeme bielu kompozitnú výplň nakoľko výplne amalgámu nie sú ideálne pre príjemný a žiarivý úsmev. Zuby kvôli nim časom stmavnú. Preto v dnešnej modernej dobe uprednostňujeme kompozitné výplne, ktoré dokonale ladia s prirodzenou farbou zubov. A tiež nedá nespomenúť, že amalgámový odpad sa podľa prijatých zákonov definuje ako nebezpečný odpad, produkovaný pri dentálnej starostlivosti o pacientov.
              </p>
              <h6>Endodoncia = Liečba koreňového kanálika</h6>
              <p>
      Ďalšou extrémne frekventovanou liečbou vykonávanou v našej ambulancii je liečba koreňového kanálika, ktorá rieši infekciu zubnej drene. Keď pacient trpí týmto stavom, môže mať problémy, ako je zvýšená senzitivita na chlad alebo teplo, bolesť pri zahryznutí, bolesť, ktorá vyžaruje do inej oblasti, ako je krk, spánok a ucho a stupňuje sa večer, k čomu sa pridružuje nespavosť. 
      Počas liečby koreňového kanálika odstráni lekár zinfikovanú zubnú dreň. Ošetrená oblasť sa potom sterilizuje a uzavrie, takže do nej nemôžu znova vstúpiť žiadne baktérie a spôsobiť ďalšiu infekciu. Celý tento proces realizujeme príslušným endomotorom a následne kanálik zapĺňame thermafilovým čapom nahriatym v piecke.
              </p>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <Accordion>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            <div className="d-flex align-items-center py-2">
              <h5>Estetická stomatológia </h5>
              <FaAngleDown size={30} className="ml-auto mb-1 mr-2"/>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <div className="mt-3">
              <p>
      Poskytneme vám požadovaný úsmev a obnovíme aj optimálne zdravie ústnej dutiny.
              </p>
              <ul>
                <li>Obnovenie jasu sfarbených zubov pomocou BIELIACICH techník, faziet, keramických koruniek.</li>
                <li>Vylepšenie odštiepených zubov fazetami či korunkami.</li>
                <li>Uzatvorenie medzery, či chýbajúceho zuba buď ambulantne na jednom sedení premostením, alebo za pomoci laboratória.</li>
                <li>Nahradenie starých výplní amalgámu.</li>
                <li>Nahradenie odfarbených koruniek keramickými korunkami.</li>
                <li>Oprava krivých zubov korunkami.</li>
                <li>Dlahovanie zubov pri progredujúcej parodontitíde.</li>
                <li>Postupy na opravu zubov pri nadmernom opotrebovaní.</li>
                <li>Dostavba resp. rekonštrukcia zubov za použitia sklených čapov</li>
              </ul>
              <p>
    Fazety - sú ako tenký plášť natrvalo spojený s povrchmi zubov, aby sa zlepšila ich poloha, tvar a odtieň, ako aj celková estetika úsmevu človeka. Vyrábajú sa buď z kompozitnej živice alebo z  porcelánu. Predtým, ako ich urobíme, budeme musieť odstrániť veľmi malé množstvo skloviny, aby sa po nanesení tenkých porcelánových faziet nezdali zuby objemné. Fazety sú kozmetickým riešením pre rôzne scenáre, medzi ktoré patria:
              </p>
              <ul>
                <li>Staršie fazety, ktoré si vyžadujú výmenu</li>
                <li>Medzery medzi zubami</li>
                <li>Zlomené, pokrivené alebo odštiepené, opotrebované zuby</li>
                <li>Trvale zafarbené alebo sfarbené zuby</li>
              </ul>
            </div>
          </Accordion.Collapse>
        </Accordion>
        <h5 className="list-item my-4">Snímateľné náhrady- štandardné a nadštandardné protézy</h5>
        <h5 className="list-item my-4">Extrakcie zubov</h5>
        <h5 className="list-item my-4">Intraorálny RTG</h5>

      </Container>
    </Styles>
  </Layout>
)

const Styles = styled.div`

`

export default Services